<template>
  <scroll-container>
    <Container>
      <spinner :loading="loading" :size="'3em'" class="scroll-trigger-container" />
      <div v-show="!loading">
        <CardSection v-if="leadRequest && leadRequest.lead_request_quote_created == '1'">
          <card>
            <template #title>A quote has already been created for this project</template>
            <p>To create a new quote, please import a new project</p>
          </card>
        </CardSection>
        <LeadRequestQuoteBody v-else-if="leadRequest" :leadRequest="leadRequest" />
      </div>
    </Container>
  </scroll-container>
</template>

<script>
import PageMixin from '../mixins/Page'
import LeadRequestQuoteBody from '../bodies/LeadRequestQuote.vue'

export default {
  mixins: [PageMixin],
  components: { LeadRequestQuoteBody },

  data() {
    return {
      getLeadRequestLoading: false,
      leadRequest: null
    }
  },

  computed: {
    loading() {
      return this.getLeadRequestLoading
    }
  },

  watch: {},

  methods: {
    async getLeadRequest() {
      this.getLeadRequestLoading = true

      const { object } = await this.$store.dispatch('LeadRequest/fetch', {
        id: this.$route.params.id
      })
      this.leadRequest = object

      this.getLeadRequestLoading = false
    }
  },
  async mounted() {
    await this.getLeadRequest()
  }
}
</script>
